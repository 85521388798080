import '../css/search.scss';
import {liveLoadData, filterLoad, submitData, dataFromForm, getData} from './lib/livedata';
import {strToArray} from './lib/helpers';
import {getServerUrl} from './lib/url';
import {minDays, shortDays, longDays, shortMonths, longMonths, dateMidFormat, dateMidScheme} from './lib/locales';
import {createCookie, checkCookie, readCookie, deleteCookie, searchToCookieName} from './lib/cookie';

$(document).ready(function () {
	
	if(checkCookie('back')){
//		console.log('usuwam cookie back');
		deleteCookie('back');
	}
	
	var $header = $('#site-header'),
		$linkHome = $header.find('.nav-item.active .nav-link-home'),
		$box = $('main'),
		$fill = $box.find('#liveDataLoad'),
		$admin = $box.find('.container-admin'),
		$video = $box.find('.page-header');

	if(checkCookie('video')){
		var vCookie = parseInt(readCookie('video'),10);
//		console.log('odczytane vCookie', vCookie);
		switch (vCookie) {
			case 1: 
				if($video.is(":hidden")){
					$video.show();
				}
				$linkHome.removeClass('ready');
				break;
			default:
				if($video.is(":visible")){
					$video.hide();
				}
				if(!$linkHome.hasClass('ready')){
					$linkHome.addClass('ready');
				}
		}
	}else{
		$video.show();
		if($linkHome.hasClass('ready')){
			$linkHome.removeClass('ready');
		}
		createCookie('video', 1, 0.02);
//		console.log('nowe vCookie', readCookie('video'));
	}
	
	
	////////////////// ORG /////////////////
	
	$box.on('click', '#page .admin-action.btn', function () {
		console.log('search click', $(this));
		var action = $(this).data('action');
		console.log('action', action);
		$(this).blur();
		if (action === 'submit') {
			$box.find('#page form').submit();
		} else {
			$box.find('#page .db').removeClass('db').addClass('dn-tmp');
			$box.find('#page .dn').removeClass('dn').addClass('db');
			$box.find('#page .dn-tmp').removeClass('dn-tmp').addClass('dn');
		}
	});
	
	$header.on('click', '#scroll-item', function(){
		$('html, body').animate({
			scrollTop: $("#afterheader").offset().top
		}, 1500);
	});

	
	$linkHome.on('click', function(e){
		$(this).blur();
		e.preventDefault();
		console.log('$video.hidden', $video.is(":hidden"));
		switch ($video.is(":hidden")) {
			case true: 
				$video.slideDown('slow');
				$linkHome.removeClass('ready');
				createCookie('video', 1, 0.02);
				break;
			case false:
				$video.slideUp("slow");
				$linkHome.addClass('ready');
				createCookie('video', 0, 0.02);
		}
		clearSearch($fill);
	});

	// dev: sprawdzam, czy szablon ma prawidłowe pola i loguję do konsoli wynik testu
	if ($fill === undefined) {
		console.log('Błąd', 'Brak miejsca docelowego #liveDataLoad dla danych na ' + getServerUrl());
	} else if ($fill.find('#searchForm') === undefined) {
		console.log('Brak wyszukiwania', 'Formularz #searchForm nie występuje na ' + getServerUrl());
	} else {
		console.log('Dane', 'Pobieranie danych na ' + getServerUrl());

		/////// WYSZUKIWARKA //////
		var $sFill = $box.find('#searchForm').data('fill') !== undefined ? $box.find($box.find('#searchForm').data('fill')) : $fill;
		searchToCookie($box.find('#searchForm'));

		// select
		$box.on('change', '#searchForm select', function () {
			console.log('find select::' + $(this).prop('name') + ':', $(this).val());
			$box.find('input[name=page]').val(1);
			searchToCookie($box.find('#searchForm'));
			liveLoadData($box.find('#searchForm'), $sFill);
		});
		// input
		$box.on('keypress', '#searchForm input', function (e) {
			if (e.which === 13)
				return false;
		});
		$box.on('keydown', '#searchForm input', function (e) {
			if (e.which === 13)
				return false;
		});
		$box.on('keyup', '#searchForm input', function (e) {
			if (e.which === 13) {
				var $isAdoption = $box.find('.search-adoption-table');
				if (($(this).is("input[type=text]") || $(this).is("input[type=number]") || $(this).is("input[type=search]")) && ($isAdoption === undefined  || !$isAdoption.length)) {
					$box.find('input[name=page]').val(1);
					console.log('find input::value', $(this).val());
					searchToCookie($box.find('#searchForm'));
					liveLoadData($box.find('#searchForm'), $sFill);
				}
				return false;
			}
		});
		$box.on('click', '#searchForm .search-btn', function () {
			var $isAdoption = $box.find('.search-adoption-table');
			if ($isAdoption === undefined || !$isAdoption.length) {
				$box.find('input[name=page]').val(1);
				searchToCookie($box.find('#searchForm'));
				liveLoadData($box.find('#searchForm'), $sFill);
			}
		});
		
		$box.on('click', '#searchForm .clear-fields', function () {
			$(this).parents('.input-group').find('input').val('');
			$box.find('input[name=page]').val(1);
			searchToCookie($box.find('#searchForm'));
			liveLoadData($box.find('#searchForm'), $sFill);
		});

		/////// PANEL ADMINA ///////

		// przełacznik dla widoku aktywne/nieaktywne
		$admin.on('click', '.toggle-display', function () {
			var $btn = $(this);
			$(this).blur();
			if ($btn.hasClass('active')) {
				console.log('click button .toggle-display: is active, exit.', $btn);
				return false;
			} else {
				console.log('click button .toggle-display: is inactive, go:', $btn);
				$btn.addClass('active');
				$fill.find('input[name=page]').val(1);
				$fill.find('#searchForm #find-type').val($btn.data('display'));
				$btn.parent().find('.toggle-display').removeClass('active');
				$btn.addClass('active');
				searchToCookie($fill.find(('#searchForm')));
				liveLoadData($box.find('#searchForm'), $fill);
			}
		});

		//////// MENU KONTEKSTOWE ////////

		// przełącznik listy w menu
		$box.on('click', '#content-menu .btn, #content-sm-menu .btn', function () {
			var $btn = $(this);
			$(this).blur();
			console.log('click button #content-menu', $btn);
			$fill.find('input[name=page]').val(1);
			$box.find('#searchForm #find-type').val($btn.data('display'));
			searchToCookie($box.find(('#searchForm')));
			liveLoadData($box.find('#searchForm'), $fill);
		});
		// przełącznik listy "więcej"
		$box.on('click', '.type-button-row .btn', function () {
			var $btn = $(this);
			$(this).blur();
			console.log('click button #content-menu', $btn);
			$box.find('input[name=page]').val(1);
			$box.find('#searchForm #find-type').val($btn.data('display'));
			searchToCookie($box.find(('#searchForm')));
			liveLoadData($box.find('#searchForm'), $fill);
			scroll_to('#liveDataLoad');
		});

		//////// MODUŁ ZBIÓRKI NA LIŚCIE ZBIÓREK ////////

		// przyciski admina w stopce (z akcją dla metody get)
		$fill.on('click', '.card-footer .admin-actions button', function () {
			var action = $(this).data('action'),
				fill = $(this).data('fill');
			console.log('fill',fill);
			$(this).css('color', 'silver');
			$(this).css('background-color', 'transparent');
			$(this).prop('disabled', true);
			if(fill !== undefined){
				getData(action, $box.find(fill));
			}else{
				// najpierw będzie wykonana metoda get z akcją, potem post dla aktualizacji listy po akcji
				getData(action, null, null, $box.find('#searchForm'), $fill);
			}
		});
	}

	// placeholder for date
	// uwaga odnośnie tłumaczenia, jak niżej
	var $birthday = $("#birthday"),
			placeholder = $birthday.prop('placeholder') + ' (' + dateMidScheme() + ')';
	console.log("#birthday placeholder", placeholder);
	$birthday.prop('placeholder', placeholder);

	// activation datepicker
	// nazwy krajowe należy pobierać z tłumaczeń  w kontrolerze, a nie z plikóœ js - do zmiany
	$("#birthday").datepicker({
//		changeMonth: true,
//		changeYear: true,
		dateFormat: dateMidFormat(),
		showAnim: 'blind',
		yearRange: '-110:+0',
		minDate: '-110Y',
		maxDate: 0,
		firstDay: 1,
		monthNamesShort: shortMonths(),
		monthNames: longMonths(),
		dayNamesShort: minDays(),
		dayNamesMin: minDays(),
		dayNames: longDays(),
		showMonthAfterYear: true,
		appendText: dateMidScheme()
	});

	// pasek admina
	var $adminBar = $box.find('.container-admin');
	if ($adminBar !== undefined) {
		$adminBar.slideDown(700);
	}

	// okno modalne
	var $bModal = $('#block-modal');
	if ($bModal !== undefined) {
		var $modal = $bModal.find('#accordionForms');
		// zapis
		$modal.on('click', '.btn-save', function () {
			var $btn = $(this);
			formSend($($btn.data('form')), $btn.data('action'), $btn.data('fill'));
		});
		// rezygnacja
		$modal.on('click', '.btn-clear', function () {
			var $btn = $(this);
			submitData({}, $btn.data('action'), $btn.data('fill'));
		});
		// otwarcie karty zbiórki z pobraniem listy osób czekających na zbiórkę
		$modal.on('click', '.btn-link', function () {
			console.log('Otwarcie karty zbiórki w oknie modalnym',$(this).data('target'));
			if ($(this).data('target') === '#collapseCollection' && $(this).attr('aria-expanded') === 'false') {
				var $btn = $(this).parents('.card').find('.btn-clear');
				submitData({}, $btn.data('action'), $btn.data('fill'));
			}
		});
		
		// w oknie modalnym ustawienie przycisków dodawania zbiórki
		if($('#collapseCollection') !== undefined){
			var $cartN = $('#collapseCollection');
			var $select = $cartN.find('select[name="necessitous_id"]');
			var val = $select.val() !== undefined && $select.val().length ? $select.val() : 0;
			switch (val){
				case 0:
					$cartN.find('.card-footer .btn').each(function(){
						$(this).prop('disabled', true);
					});
					break;
				default :
					$cartN.find('.card-footer .btn').each(function(){
						$(this).prop('disabled', false);
					});
					break;
			}
		}
		
		$modal.on('change', 'select[name="necessitous_id"]', function(){
			var $cartN = $('#collapseCollection');
			var $select = $(this);
			var val = $select.val() !== undefined && $select.val().length ? $select.val() : 0;
			console.log('necessitous_id', val);
			if(val){
				var text = $select.find('option:selected').text().trim();
				$cartN.find('input#title').val(text);
				$cartN.find('.card-footer .btn').each(function(){
					$(this).prop('disabled', false);
				});
			}else{
				$cartN.find('input#title').val('');
				$cartN.find('.card-footer .btn').each(function(){
					$(this).prop('disabled', true);
				});
			}
		});
	}

	////////// Ostatnie czynności po załadowniu szkieletu strony /////////

	// aktywacja tooltips (dymków)
	$('[data-toggle="tooltip"]').tooltip();
});

function nextModule($btn){
	var bootstrap_width = $('body').data('width'),
		$group = $btn.parents('.group-type'),
		$prevBtn = $group.find('.btn-prev');
	$group.find($btn.data('hide')).removeClass('d-block').addClass('d-none');
	$group.find($btn.data('view')).removeClass('d-none').addClass('d-block');
	switch (bootstrap_width){
		case 'xs':
		case 'sm':
			if($btn.data('view') === '.col-idx-1'){
				$btn.data('view', '.col-idx-2').data('hide', '.col-idx-1');
				$prevBtn.removeClass('d-none').data('view', '.col-idx-0').data('hide', '.col-idx-1');
			}else if ($btn.data('view') === '.col-idx-2') {
				$btn.addClass('d-none');
				$prevBtn.data('view', '.col-idx-1').data('hide', '.col-idx-2');
			}
			break;
		case 'md':
			$btn.addClass('d-none');
			$prevBtn.removeClass('d-none');
			break;
		case 'lg':
		case 'xl':
			break;
	}
	$btn.blur();
}

function prevModule($btn){
	var bootstrap_width = $('body').data('width'),
		$group = $btn.parents('.group-type'),
		$nextBtn = $group.find('.btn-next');
	$group.find($btn.data('hide')).removeClass('d-block').addClass('d-none');
	$group.find($btn.data('view')).removeClass('d-none').addClass('d-block');
	switch (bootstrap_width){
		case 'xs':
		case 'sm':
			if($btn.data('view') === '.col-idx-1'){
				$btn.data('view', '.col-idx-0').data('hide', '.col-idx-1');
				$nextBtn.removeClass('d-none').data('view', '.col-idx-2').data('hide', '.col-idx-1');
			}else if ($btn.data('view') === '.col-idx-0') {
				$btn.addClass('d-none');
				$nextBtn.data('view', '.col-idx-1').data('hide', '.col-idx-0');
			}
			break;
		case 'md':
			$btn.addClass('d-none');
			$nextBtn.removeClass('d-none');
			break;
		case 'lg':
		case 'xl':
			break;
	}
	$btn.blur();
}

function clearSearch($fill){
	var newLoad = false;
	if($fill.find('#searchForm #find-type').val() !== 'home'){
		$fill.find('#searchForm #find-type').val('home');
		newLoad = true;
	}
	if($fill.find('#searchForm #find-region').val() !== ''){
		$fill.find('#searchForm #find-region').val('');
		newLoad = true;
	}
	if($fill.find('#searchForm #find-phrase').val() !== ''){
		$fill.find('#searchForm #find-phrase').val('');
		newLoad = true;
	}
	if(newLoad){
		searchToCookie($fill.find(('#searchForm')));
		liveLoadData($fill.find('#searchForm'), $fill);
	}
}

function searchToCookie($form){
	var data = dataFromForm($form), 
		$searchCookie = $form.find('input[name=cookie'),
		cookieName = $searchCookie !== undefined && $searchCookie.length ? $searchCookie.val() : 'search';
	createCookie(cookieName, data, 1);
}

// zapis w oknie modalnym
// $blockForm - widok z formularzem do włożenia na stronę
function formSend($form, action, fill) {
	var goodData = true;
	// sprawdzenie wymaganych inputów i checkboxów, czy mają wartość
	$form.find('input').each(function () {
		var $inp = $(this);
		if ($inp.prop('required') && (
				($inp.prop('type') === 'checkbox' && !$inp.isChecked)
				||
				($inp.prop('type') !== 'checkbox' && !$inp.val().trim().length)
				)) {
			$inp.addClass('error');
			goodData = false;
		} else if ($inp.hasClass('error')) {
			$inp.removeClass('error');
		}
	});
	// sprawdzenie wymaganych selectów, które mają ID jako wartość
	$form.find('select').each(function () {
		var $sel = $(this);
		if ($sel.prop('required') && !parseInt($sel.val(), 10)) {
			$sel.addClass('error');
			goodData = false;
		} else if ($sel.hasClass('error')) {
			$sel.removeClass('error');
		}
	});
	// jeśli nie wszystkie wymagane pola są wypełnione, kończymy
	if (!goodData) {
		return false;
	}
	// zbieramy dane z aktualnego formularza (osoba lub zbiórka)
	var data = dataFromForm($form);
	// wysyłamy dane do kontrolera php wskazując, co ma podmienić nowy widok
	if ($form.prop('id') === 'formCollection') {
		// jeżeli dodajemy zbiórkę, niech się doda blok zbiórki do listy zbiórek
		var $box = $('main'),
				$fillContent = $box.find('#liveDataLoad'),
				$formFind = $fillContent.find('#searchForm');
			submitData(data, action, fill, 'html', $formFind, $fillContent);
	} else {
		// jeśli to osoba, to aktualizujemy tylko sam formularz w oknie modalnym
		submitData(data, action, fill);
	}
};

// slider-range (nieużywane tu)
function sliderRange(amountMin, amountMax) {
	var $box = $('main'); 
	$box.find('#slider-range').slider({
		range: true,
		min: amountMin,
		max: amountMax,
		step: 10000,
		values: [amountMin, amountMax],
		slide: function (event, ui) {
			$box.find('#amount-min').val(ui.values[ 0 ]);
			$box.find('#amount-max').val(ui.values[ 1 ]);
		}
	});
};

// obecnie nieużywane, służyło do dopasowania filmu do ekranu
function scaleToFill() {
	$('.titleBackVid').css('visibility', 'hidden');
	$('.titleBackVid').css('display', 'none');
	var $container = $('div.page-header'),
			containerWidth = $container.width(),
			containerHeight = $container.height(),
			tagRatio = containerWidth / containerHeight;
	$video = {};

	if (containerWidth < 600) {
		$video = $('#smallVid');
	} else if (containerWidth >= 800) {
		$video = $('#bigVid');
	} else {
		$video = $('#midVid');
	}
	$video.css('display', 'block');
	var videoRatio = $video.width() / $video.height();

	if (videoRatio < tagRatio) {
		$video.css('-webkit-transform', 'scaleX(' + tagRatio / videoRatio + ')');
	} else if (tagRatio < videoRatio) {
		$video.css('-webkit-transform', 'scaleY(' + videoRatio / tagRatio + ')');
	}
	$video.css('width', containerWidth);
	$video.css('height', containerHeight);
	$video.css('visibility', 'visible');
}

function scroll_to(selector) {
    $('html,body').animate({scrollTop: $(selector).offset().top}, 400);
    return false;
} 